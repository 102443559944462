<template>
  <main>
    <section-title>Harvesting Overview</section-title>
    <implementation-pending />
  </main>
</template>

<script>
export default {};
</script>
